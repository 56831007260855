import React from "react";
import {
  BannerPage,
  Colors,
  ContainerContentFixed,
  ContainerCta,
  InternalLinkTypes,
  Layout,
  SEO,
} from "../components";
import { Row, Col } from "antd";

const colProps = {
  md: 8,
  sm: 24,
  style: {},
};

const serviceContainerStyles = {
  margin: "16px",
  padding: "2rem 1rem ",
  backgroundColor: "white",
  height: "100%",
};
const Services = () => (
  <Layout>
    <SEO title="Our Services" description={"Custom home builder services"} />
    <BannerPage title={"Our Services"} />
    <div>
      <ContainerContentFixed>
        <p style={{ fontSize: "1.5rem", fontWeight: 400 }}>
          Grandeur Homes remain one of the leading custom home builders in
          Australia providing a new dimension to your lifestyle in a spectacular
          way.
        </p>
      </ContainerContentFixed>
    </div>
    <div style={{ backgroundColor: Colors.LightGray }}>
      <ContainerContentFixed>
        <Row gutter={[8, 8]}>
          <Col {...colProps}>
            <div style={serviceContainerStyles}>
              <h1 style={{ fontWeight: "200", color: Colors.DarkGreen }}>
                Custom Homes
              </h1>
              <h2 style={{ fontWeight: "100" }}>Single & Double Storey</h2>
              <p>To learn more, make an appointment with our sales team</p>
            </div>
          </Col>
          <Col {...colProps}>
            <div style={serviceContainerStyles}>
              <h1 style={{ fontWeight: "200", color: Colors.DarkGreen }}>
                Multi Unit Developments
              </h1>
              <h2 style={{ fontWeight: "100" }}>
                Single & Double Storey Townhouses
              </h2>
              <p>To learn more, make an appointment with our sales team</p>
            </div>
          </Col>
          <Col {...colProps}>
            <div style={serviceContainerStyles}>
              <h1 style={{ fontWeight: "200", color: Colors.DarkGreen }}>
                House & Land Packages
              </h1>
              <h2 style={{ fontWeight: "100" }}>
                Available in leading estates
              </h2>
              <p>To learn more, make an appointment with our sales team</p>
            </div>
          </Col>
        </Row>
      </ContainerContentFixed>
    </div>
    <ContainerCta
      title={"Your future happiness is waiting"}
      subTitle={"Start building your custom Grandeur Home"}
      secondaryLink={InternalLinkTypes.GetStarted}
      secondaryText={"Get Started"}
    />
  </Layout>
);

export default Services;
